import FlextLogo from "public/logo.svg";
import Logo from "public/D.svg"
export const HEADER = {
  logo: {
    href: "/",
    title: <Logo />,
    alt: "Logo",
  },
  nav: [
    {
      href: "/",
      title: "Home",
      alt: "Country roads..",
      desktop: false,
    },
    {
      href: "/#about",
      title: "About",
      alt: "More about me.",
      desktop: true,
    },
    {
      href: "/projects",
      title: "Projects",
      alt: "Work I've done.",
      desktop: true,
    },
    // {
    //   href: "/books",
    //   title: "Books",
    //   alt: "Books I've read.",
    //   desktop: true,
    // } ,
    // {
    //   href: "/gallery",
    //   title: "Gallery",
    //   alt: "Images and stuff",
    //   desktop: true,
    // },
    // {
    //   href: "/resume",
    //   title: "Resume",
    //   alt: "My professional resume",
    //   desktop: true,
    // },
  ],
  profileNav: [],
};
